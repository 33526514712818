body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue Pro', sans-serif;
}

:root {
  --primary-color: #fff;
  --secondary-color: #888888;
  --error-color: #f4516c;
  --success-color: #4ccd37;
  --dark-color: #888;
  --light-dark-color: #959ba2;
}