.display-none {
  display: none !important;
}

span.thank_you_email {
  color: var(--primary-color);
  cursor: pointer;
}

.thank_you_email-wrapper {
  align-items: center;
  flex: 0 1 70%;
}

.thank_you_email-content {
  border-radius: 24px;
  background: rgba(19, 19, 19, 0.5);
  backdrop-filter: blur(32px);
  min-width: 100%;
  padding: 70px 10% 40px 10%;
}