.m-login__log {
  margin: 8px 0 9px 0;

  img {
    max-height: 66px;
  }
}

.topbar-dashb .topBarLogo {
  max-height: 38px;
  margin-bottom: -5px;
}