.cancel-button {
    display: inline-block;
    transition: none;
    text-align: center;
    background: transparent;
    white-space: nowrap;

    width: auto;
    height: 39px;
    padding: 0.65rem 1rem;
    margin: 0;

    border: 1px solid #888888;
    border-radius: 60px;

    font-size: 16px;
    font-weight: 300;

    line-height: 0.9;
    opacity: 0.9;
    color: #888888;
    cursor: pointer;

    &:hover:not(:disabled):not(.active) {
        border-color: #131313;
        color: #131313;
    }

    &:focus {
        border-color: #131313;
        color: #131313;
    }
}

.modal-header,
.nav-settings {
    .cancel-button {

        &:focus,
        &:hover:not(:disabled):not(.active) {
            border-color: #fff;
            color: #fff;
        }
    }
}