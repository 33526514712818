.AddCirclePlus {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: end;
    aspect-ratio: 1;
    background-color: #990000;
    border-radius: 100%;
    cursor: pointer;

    &:hover {
        background-color: #DB422D;
    }
}