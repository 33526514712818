.topbar-company-logo {
  margin: 9px 0;

  .company-logo2 {
    width: 145px;
    height: 45px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &.logo-default {
      color: #5e6774;
      text-align: center;
      line-height: 45px;
      border: 1px solid #DADADA;
      font-size: 16px;
      font-weight: 500;
    }

    .link-company-logo-disabled {
      pointer-events: none;
    }

    .image {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.company-logo.logo-default {
  background-color: #fff;
  width: 100% !important;
  min-width: 145px;
}