.is-large-button {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: 0.65rem 1rem;
    background: transparent;
    border: 1px solid #990000;
    border-radius: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #990000;
    box-shadow: none;

    &:hover:hover:not(:disabled):not(.active) {
        background: #990000;
        border: 1px solid #990000;
        box-shadow: none;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        transition: none;
    }

    &:disabled {
        border-color: #767676 !important;
        color: #767676 !important;
    }

    &.reset-filters-btn {
        border: 1px solid #131313;
        color: #131313;

        &:hover:hover:not(:disabled):not(.active) {
            border: 1px solid #131313;
            background: #131313;
            color: #fff;
        }
    }
}
